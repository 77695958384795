import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Voice is the most natural interface we have available to us. It creates a frictionless environment for people to casually ask for the weather, play a game, or turn the lights on in their house. These are pretty simple use cases, but voice is changing the way the world runs. The potential for voice is huge, and its place in the future is solid. SuperBot 2019 brought together a panel of experts working in voice to discuss some of the ways voice has changed the landscape and what we can expect in the next few years.`}</p>
    <p>{`Moderator Mari Lescaille of `}<a parentName="p" {...{
        "href": "https://www.voicefirstlabs.com/"
      }}>{`VoiceFirst Labs`}</a>{` led the discussion among David Browning of NBA Digital, Jonathan Myers of Earplay, Sandy Diep of Google Assistant, and Mark Mezrich from Viacom.`}</p>
    <p>{`Advancements in Voice`}</p>
    <hr></hr>
    <p>{`Jonathon Myers of `}<a parentName="p" {...{
        "href": "https://www.earplay.com/"
      }}>{`Earplay`}</a>{` recalled a time when voice technology was not quite as accessible. The tools were more enterprise-geared and not realistic for the average person to use, a reality which has since changed. The last few years saw the introduction of Siri and Alexa, and since then, the general public has an entire free ecosystem at its disposal. According to Myers, developing a voice skill is something that anyone can accomplish now.`}</p>
    <p>{`Not only has it gotten easier for the average person, but large corporations have also reaped the benefits from the advancements made in the last few years. `}<a parentName="p" {...{
        "href": "https://media.turnersports.com/ncaa-march-madness-2"
      }}>{`NBA Digital`}</a>{`, for example, has seen its cost go down since it developed its first voice skill. According to David Browning, Sr. Product Manager at NBA Digital, the first voice skills he developed required a lot of manual processes to build and test. As he’s continued to work with voice, Browning has been able to scale more efficiently and he predicts it won’t be long until it’s possible to export conversation flows to different platforms through content management systems, further bringing down the cost and time required.`}</p>
    <p>{`Sandy Diep, Head of `}<a parentName="p" {...{
        "href": "https://developers.google.com/actions/"
      }}>{`Google Assistant Developer Partnerships`}</a>{`, said improvements in natural language understanding allows the Google Assistant to understand human speech even better than humans can. She also noted that she hopes that communicating between languages gets simpler in the future and support for multilingual across different platforms grows.`}</p>
    <p>{`Voice in Cars`}</p>
    <hr></hr>
    <p>{`Most of the U.S. has restrictions on what you can do with your phone in the car, which created a brand new hands-free environment. Coupled with the fact that commute hours are on the rise due to the `}<a parentName="p" {...{
        "href": "https://www.brookings.edu/wp-content/uploads/2016/07/Srvy_JobsProximity.pdf"
      }}>{`gap between jobs in metropolitan areas and housing`}</a>{`, that’s a lot of additional hours where someone can’t access their phones– making audio-first solutions a natural choice.`}</p>
    <p>{`“People are listening to audio in hours that they didn’t before, and that’s an opportunity that’s only going to continue to grow for us, “ said Mark Mezrich, VP of Product Management, Emerging Products at `}<a parentName="p" {...{
        "href": "https://www.viacom.com/"
      }}>{`Viacom`}</a>{`. Browning adds that voice capability in the car is “everything for him” as one of the last land-grabs available in the digital space.`}</p>
    <p>{`Radio is by far the `}<a parentName="p" {...{
        "href": "https://www.edisonresearch.com/am-fm-radio-still-dominant-audio-in-car/"
      }}>{`dominant entertainment medium in cars`}</a>{`, and Myers believes that voice is in the process of completely reinventing the radio experience. Radio as it currently exists doesn’t allow for anything other than passive listening, but Myers sees a future where a listener tunes into an interview and is able to interact with the content and be the one to ask the questions. To add to that, Diep spoke about the possibility of a companion experience and having live-action dialogue alongside the experience. She also touched on the accessibility aspect of voice and how it allows people to connect with the world in a way they previously couldn’t.`}</p>
    <h2>{`Looking forward`}</h2>
    <p>{`According to Mezrich, there’s a natural growth curve in voice that he believes we’re going to continue to see. The entry price for a voice device is low, and he’s seeing more and more people purchasing a voice device and adopting it in their everyday lives. However, he and Browning both see a discovery issue that needs to be addressed. For example, when a user asks for the score of an NBA game, the voice assistant can default to `}<a parentName="p" {...{
        "href": "https://voicebot.ai/2019/02/23/better-business-conversations-start-with-third-party-voice-apps-but-first-party-rules-today/"
      }}>{`first party functionality`}</a>{` instead of directing the question to a third party app.`}</p>
    <p>{`“We try to adopt an SEO mindset and figure out what people are organically asking for anyway that `}{`[`}{`Viacom`}{`]`}{` can have an offering for,” said Mezrich. “We’re working with the platforms to make sure that we can be in there in a meaningful way and serve up the right content to the right people.”`}</p>
    <p>{`Diep also stressed that discovery was going to be a big focus for platforms moving forward. “Being able to offer users the content they want at the time that they need it is going to be crucial,” she said. “In order for us to unlock discoverability, we have to be able to meet users where they are and respond in context even if it switches.”`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+future+of+voice+panel"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      